/* Adjusted padding for tag-selects */
.tag-selects-padding-bottom {
  padding-bottom: 20px;
}

/* Styles for the instruction input within tag-selects
.tag-selects-instruction-input {
} */

/* Styles for form control within tag-selects
.tag-selects-form-control {
} */

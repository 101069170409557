/*#mainHeader { display:none; }*/
/*body { padding-top:0; }*/
.tracking {
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin-top:-80px;
}
.tracking-wrapper {
    max-width:350px;
    margin:0 auto;
}
.tracking-wrapper-inner {
    margin:0 10px;
    border:1px solid rgba(51, 122, 183, 0.2);
    border-radius:3px;
    padding:20px;
}

.MuiTypography-subtitle1 {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 14px!important;
    font-weight:600!important;
    letter-spacing: 0!important;
}



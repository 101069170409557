#tidio-chat-iframe {
    position: fixed !important;
    right: 20px !important;
    bottom: 20px !important;
    z-index: 9999 !important;
  }
  
  #tidio-chat-container {
    position: fixed !important;
    right: 20px !important;
    bottom: 20px !important;
    z-index: 9999 !important;
  }
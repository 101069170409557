

  /* Styles for the outer container */
.tag-textfield-editable-container {
  margin-bottom: 20px;
}

/* Styles for the grid item containing the text field
.tag-textfield-editable-item {
} */

/* Styles for the TextField component */
.tag-textfield-editable-input {
  width: 100%;
}

/* Styles for the root of the input element inside TextField */
.tag-textfield-editable-input-root {
  height: 40px;
}

/* Styles for the grid item containing the custom icon button
.tag-textfield-editable-icon {
} */

/* Styles for the CustomIconButton component */
.tag-textfield-editable-custom-icon {
  height: 55px;
}

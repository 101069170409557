.panel {
    box-shadow: 0px 0px 20px 8px rgba(0,0,0,0.12);
    background-color: white;
    padding: 20px;
    margin: 5px;
    border-radius: 4px;
}
#pageContent {
    padding: 0 3px;
}
@media (min-width: 992px) {
    .panel {
        margin:10px;
    }
    #pageContent {
        padding: 0 5px;
    }
}
@media (min-width: 1400px) {
    .panel {
        margin:15px;
    }
    #pageContent {
        padding: 0 8px;
    }
}

/* Typography in panels ========================== */

.panel h6 {
    text-transform: uppercase;
    margin: 0 0 0.5rem;
    color: rgb(64, 64, 68);
    font-size: 0.8rem;
    font-weight: 600;
  }
.panel h3 {
    margin: 0 0 0.5rem;
    color: black;
    font-size: 1rem;
    font-weight: 700;
}

/* copy button */
.panel h3 .MuiIconButton-sizeSmall {
    padding:0;
    /* margin-left: 0.5rem; */
}
.panel h3 .MuiIconButton-sizeSmall:hover {
    background-color: transparent;
}
.panel h3 .MuiIconButton-sizeSmall .MuiSvgIcon-root {
    width: 0.6em;
    height: 0.6em;
    font-size: 1.5rem;
}

@media only screen and (max-width: 1279.89px) {
    .panel .text-mobile-end {
        text-align: end;
    }
}

/* Stepper ========================== */
.panel .border-mobile-bottom {
    padding-left:2%;
}
@media only screen and (max-width: 1279.89px) {
    .panel .MuiStepper-root {
        padding: 35px 0 0;
    }
    .panel .border-mobile-bottom {
        border-bottom: 2px solid rgb(207, 214, 224);
        padding-left:0;
    }
}

/* Expandable list ===================== */

.panel .MuiList-root {
    border:1px solid #337AB7;
    border-radius: 8px;
}
.panel .MuiList-padding {
    padding: 8px 12px;
}
.panel .MuiList-root .MuiListItem-root {
    cursor: pointer;
}
.panel .MuiList-root .MuiCollapse-root {
    border-top:1px solid #337AB7;
    margin-top:15px;
    padding-top:15px;
}
.panel .MuiList-root .MuiCollapse-root h6 {
    margin-bottom:5px;
}
.panel .MuiList-root .MuiCollapse-root p {
    margin-bottom:20px;
}
/* CSS for TagTimePicker component */

/* Styles for the container that wraps the entire time picker
.time-picker-container {
} */

/* Styles for the grid item that contains the time picker
.time-picker-item {
} */

/* Styles for the TimePicker component itself */
.time-picker {
  width: 280px;
}


/* Styles for the container Grid
.tag-number-field-editable-container {
} */

/* Styles for the TextField input */
.tag-number-field-input {
  height: 40px; /* Matches inline style style={{ height: 40 }} */
}

/* Styles for the warning Typography */
.tag-number-field-warning {
  margin-top: 15px; /* Matches inline style style={{ marginTop: 15 }} */
}

/* Styles for the CustomIconButton */
.tag-number-field-icon {
  height: 55px; /* Matches inline style style={{ height: 55 }} */
}

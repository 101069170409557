.tags-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.active-tags-container {
  display: flex;
  flex-direction: column;
}

.active-tags-container > * {
  margin-bottom: 20px;
}

.inactive-tags-section {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 24px;
  background-color: #e0e0e0; /* Darker container background */
  width: fit-content;
  min-width: 300px;
}

.section-title {
  margin-bottom: 16px;
  color: #666;
  padding-left: 4px;
}

.inactive-tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Allow wrapping of inactive tags */
  overflow-x: hidden; /* Prevent horizontal scrollbars */
  padding: 10px;
  margin-bottom: 0;
  gap: 10px; /* Spacing between tags */
  max-width: 1450px;
}

.inactive-tags-container > * {
  flex: 0 0 auto;
}

.inactive-tags-container > *:last-child {
  margin-right: 0;
}

.label-wrapper-no-color {
  background-color: #f5f5f5 !important; /* Lighter button background */
}

.tag-container {
  width: auto;
  display: inline-flex;
  flex-wrap: wrap; /* Enable wrapping inside this container */
}

.div-padding {
  margin-top: 20px;
}

.flash {
  animation: flash-animation 0.5s ease-out;
}

@keyframes flash-animation {
  0% {
    background-color: yellow;
  }
  100% {
    background-color: transparent;
  }
}

.always-on {
  pointer-events: none !important;
}

.always-on .label-no-color {
  font-weight: bold;
}

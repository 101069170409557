/* PANEL 1 / ADDRESS BOOK ===================================== */

.panel-item-box {
    padding: 3%;
    margin: 0;   
}

.panel-item-box-unselected {
    padding: 1% 2%;
    margin: 0;
}

.panel-item-title {
    margin: 0;
    padding: 2% 0;
}

.panel-item-info {
    margin: 0;
    padding: 3% 0;
}

h3 {
    font-size: 16px;
    font-weight: regular;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

.panel-address-form {
    transform:translateY(-50%);
}

.fixedElement {  
    position:fixed;
    top:0;
    width:100%;
    z-index:100;
}
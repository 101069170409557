

html {
    width: '100%';
}

#account-picker-wrapper {
    position:relative;
}

#account-picker {
    position:absolute;
    top:10px;
    right:80px;
    z-index:10;
    width:350px;
}

#indicator_duration {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index:10;
    width:50px;
}
/* 
@media (max-width: 1920px) {
    #indicator_duration {
        position:absolute;
        top:5px;
        right:900px;
        z-index:10;
        width:50px;
    } 
}

@media (max-width: 1360px) {
    #indicator_duration {
        position:absolute;
        top:5px;
        right:620px;
        z-index:10;
        width:50px;
    } 
} */

@media (max-width: 767.98px) {
    #account-picker {
        position:relative;
        width:100%;
        max-width:100%;
        height: max-content;
        top: unset;
        right: unset;
    }
    #account-picker-wrapper {
        padding:5px 5px 5px 5px;
    }  
    /* #indicator_duration {
        position:absolute;
        top:5px;
        right:200px;
        z-index:10;
        width:50px;
    }   */
}

.di_badge {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #337AB7;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
    transition: all 0.5s;
}

.di_badge_check {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #65CCB4;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
}

.a_button {
    display: block;
    width: 100px;
    height: 22px;
    background: #337AB7;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    line-height: 22px;
}


.fixedElement {  
    position:fixed;
    top:0;
    width:100%;
    z-index:100;
}

.gallery {
    z-index: 10;
    position: absolute;
    background-color: rgba(255,255,255,0.5);
    margin-top:0;
    height:100%;
    width:30%;
}


.img-wrapper {
    overflow: hidden;
  }
  
  img.hover-zoom {
    transition: all 0.3s ease 0s;
    width: 100%;
  }
  img.hover-zoom:hover {
    transform: scale(1.1);
    position: relative;
    z-index: 999;
  }

  .quickIcon {
    height: 100px;
  }

  @media (min-width: 1920px) { /* For screens larger than 1080p */
    .quickIcon {
        height: 130px; /* Adjust size for larger screens */
    }
}
.page-banner {
    background: 
        linear-gradient(rgba(0,0,0, 0.4), rgba(0,0,0, 0.4)), 
        url(./orderDetail_backgroundImg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 2% 0% 6% 0%;
    margin: 0px;
    text-align: center;
    height: 30vh;
    min-height: 100px;
}

.page-banner .title {
    color: white;
    font-size: 55px;
    margin-bottom: 2px;
}

@media screen and (max-width: 576px) {
    .page-banner .title {
        font-size: 48px;
    }
}

.page-banner .subheading {
    color: white;
    margin-top: 0px;
}
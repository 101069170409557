
/* Styles for the main container
.tag-decimal-field-editable-container {
} */

/* Styles for the text field container
.text-field-container {
} */

/* Styles for the decimal text field
.decimal-text-field {
} */

/* Styles for the input field within decimal text field */
.decimal-text-field-input {
  height: 40px; /* Extracted style from inputProps */
}

/* Styles for the error message
.error-message {
} */

/* Styles for the icon button container
.icon-button-container {
} */

/* Styles for the custom icon button */
.custom-icon-button {
  height: 55px; /* Extracted style from inline style */
}


/* Styles for the main container
.tag-datetime-field-container {
} */

/* Styles for the datetime picker item
.datetime-picker-item { 
} */

.datetime-picker {
  /* Styles for the datetime picker */
  width: 280px; /* Extracted style from sx={{ width: 280 }} */
}

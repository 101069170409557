.panel-list {
    flex: 1;
    overflow-y: auto;
    margin: 2% 0;
}

.panel-list .panel-list-container {
    padding: 0px;
    margin: 0px 4px 0px 0px;
    border-radius: 0.25rem;
    border: 1px solid rgba(0,0,0,.125);
}

.panel-list .panel-list-container .panel-list-item {
    margin: 0px;
    padding: 2px;
}

/* scrollbar */

.panel-list::-webkit-scrollbar-track
{
    -webkit-box-shadow: none;
    border-radius: 10px;
    background-color:#E1F1FF;
}
.panel-list::-webkit-scrollbar
{
    width: 10px;
    background-color:#E1F1FF;
}
.panel-list::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: #337AB7;
}

.tooltip-inner {
    text-align:left!important;
    background-color:#337AB7!important;
}
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    border-top-color:#337AB7!important;
}
.addNewAddressForm, .editAddressForm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    min-width: 400px;
    max-width: 700px;
    padding: 24px;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 4px;
    background: white;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    z-index: 1001;
 }
  /* Styles for the tag-checkbox-container */

/* Add styles for the container of the TagCheckBox component */
.tag-checkbox-container {
  margin-bottom: 10px;
}

/* Styles for the checkbox label, Add styles for the FormControlLabel or Checkbox component 
.checkbox-label {
} */
